import React, { useState } from 'react'
import { useUserContext } from 'context'
import styled from 'styled-components'

import { Flex, ResponsiveSVG } from 'ui/acro'

import MyPracticeDropdownMenu from './SignedIn/MyPractice/MyPracticeDropdownMenu'
import TopCategoryDropdownMenu from './SignedIn/TopCategory/TopCategoryDropdownMenu'
import { topCategory } from '../../common/data/topCategory'

import { YogaMat } from 'images/latest'

const NavWrapper = styled.div`
  display: flex;
`

const NavItemFlex = styled(Flex)`
  /* need to use this css format for psuedo elements */
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  margin: 0px 10px;
`

const NavTitle = styled.nav`
  font-family: Arquitecta;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  margin: 0;
  position: relative;
  display: flex;
  &:after {
    content: '';
    z-index: 10;
    position: absolute;
    top: 20px;
    height: 3px;
  }
  &:after {
    width: ${(props) => (props.isHovour ? '100%' : '0')};
    transition: width 0.25s ease-in-out;
    background: currentColor;
  }
`

const DesktopMiddleNavContent = () => {
  const { user } = useUserContext()
  const [myPracticeHovered, setMyPracticeHovered] = useState(false)
  const [topCategoryHovered, setTopCategoryHovered] = useState('')

  return (
    <NavWrapper>
      {user && (
        <div
          style={{
            borderRight: '0.0625rem solid #000',
            paddingRight: '0.375rem'
          }}
        >
          <NavItemFlex
            onMouseEnter={() => setMyPracticeHovered(true)}
            onMouseLeave={() => setMyPracticeHovered(false)}
          >
            <ResponsiveSVG
              marginLeft={'0.5rem'}
              SVG={YogaMat}
              width='1.7rem'
              height='1.5rem'
            />
            <NavTitle isHovour={myPracticeHovered}>My Practice</NavTitle>
            {myPracticeHovered && <MyPracticeDropdownMenu />}
          </NavItemFlex>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          gap: '1.375rem',
          paddingLeft: '1.375rem'
        }}
      >
        {topCategory.map((item) => (
          <NavItemFlex
            key={item.key}
            onMouseEnter={() => setTopCategoryHovered(item.key)}
            onMouseLeave={() => setTopCategoryHovered('')}
          >
            <NavTitle isHovour={topCategoryHovered === item.key} key={item.key}>
              {item.value}
            </NavTitle>
            {topCategoryHovered === item.key && (
              <TopCategoryDropdownMenu
                key={item.key}
                hoverCategory={item.key}
              />
            )}
          </NavItemFlex>
        ))}
        <NavItemFlex
          key={'Instructors'}
          onMouseEnter={() => setTopCategoryHovered('Instructors')}
          onMouseLeave={() => setTopCategoryHovered('')}>
          <NavTitle isHovour={topCategoryHovered === 'Instructors'} key={'Instructors'}>
            <a href={'/explore/instructors'} key={'Instructors'}>
              Instructors
            </a>
          </NavTitle>
        </NavItemFlex>
      </div>
    </NavWrapper>
  )
}

export default DesktopMiddleNavContent
